import React from "react"
import {StaticQuery, graphql, Link} from "gatsby"
import Img from "gatsby-image";
import PropTypes from "prop-types"
// style
import './header.css'

const Header = ({ haveBackground, haveLogo, data }) => (
  <div>
    {haveBackground && (
      <div className="header-block" />
    )}
    <div className="container-header">
      {haveLogo && (
        <div className="header-container-logo">
          <Link to="/">
            <Img fixed={data.file.childImageSharp.fixed}  />
          </Link>
        </div>
      )}
      <input className="menu-btn" type="checkbox" id="menu-btn"/>
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"/>
      </label>
      <ul className="header">
        <li>
          <Link to="/agence/">L'agence</Link>
        </li>
        <li className="header-separator">|</li>
        <li>
          <Link to="/services/">Nos services</Link>
        </li>
        <li className="header-separator">|</li>
        <li>
          <Link to="/projets/">Projets</Link>
        </li>
        <li className="header-separator">|</li>
        <li>
          <Link to="/contact/">Contact</Link>
        </li>
      </ul>
    </div>
  </div>
)

const HeaderQuery = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fixed(height: 56) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => <Header data={data} {...props} />}
    />
  )
}

HeaderQuery.propTypes = {
  haveBackground: PropTypes.bool,
  haveLogo: PropTypes.bool,
}
HeaderQuery.defaultProps  = {
  haveBackground: true,
  haveLogo: true,
}
export default HeaderQuery
